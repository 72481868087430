@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.Polaris-TextField__VerticalContent {
  overflow: hidden;
}
.d-flex {
  display: flex;
}
.flex-center {
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.Polaris-DatePicker__MonthLayout {
  margin-top: 0px;
}
.date-picker-btn-group {
  display: flex;
  justify-content: flex-end;
}
.flex-end {
  justify-content: flex-end;
}
.flex-space-between {
  justify-content: space-between;
}
.ml-8 {
  margin-left: 8px;
}
.mr-4 {
  margin-right: 4px;
}
.ml-16 {
  margin-left: 1rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-16 {
  margin-top: 16px;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-16 {
  margin-bottom: 16px;
}
.pd-20 {
  padding: 20px;
}

.pb-16 {
  padding: 16px;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.break-word {
  overflow-wrap: break-word;
  word-break: break-all;
}
.Polaris-Select {
  min-width: 80px;
}
.Polaris-Select__Input {
  padding: 16px;
  min-width: 80px;
  -webkit-appearance: none;
}
.blocked-detail-icon .Polaris-Icon {
  margin: 0 !important;
}
.Polaris-PositionedOverlay {
  z-index: 603;
}

.Polaris-Pagination {
  display: flex;
  justify-content: center;
}
.color-picker {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
}
.Polaris-IndexTable__TableRow {
  cursor: auto;
}

.Polaris-Filters__Container .Polaris-TextField__Backdrop {
  border-color: #1a1a1a;
  border-width: 0.0625rem;
  background-color: rgba(247, 247, 247, 1);
  outline: 0.0625rem solid #1a1a1a;
}
.pointer {
  cursor: pointer;
}

.Polaris-IndexTable__TableCell--first .Polaris-IndexTable-Checkbox__TableCellContentContainer {
  display: none;
}
.Polaris-IndexTable__TableHeading--first {
  padding: 0 !important;
  max-width: 0;
}
.Polaris-IndexTable__TableCell--first {
  padding: 0 !important;
  max-width: 0;
}
.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: hidden;
}

@media screen and (max-width: 490px) {
  .Polaris-IndexFilters__IndexFiltersSticky {
    display: none;
  }
  .Polaris-IndexTable__TableHeading--first .Polaris-Checkbox__Backdrop {
    display: none;
  }
}
